<template>
  <div class="devicell">
    <x-table
      new-style
      :no-data-text="CA('devicell_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    ></x-table>

    <add-and-edit
      v-model="modal.show"
      :title="modal.title"
      :editData="editData"
      @submit-success="submitSuccess"
    ></add-and-edit>

    <!-- 铭牌 -->
    <Modal :width="790" v-model="nameplateShow" :closable="false">
      <div ref="nameplate" class="qrcode-container">
        <div class="qrcode-pane">
          <div class="qrcode">
            <div ref="qrcode"></div>
          </div>
          <div class="device-num">
            {{ currentBindingDevice ? currentBindingDevice.abcNo : "" }}
          </div>
        </div>
        <div class="font-info">
          <div class="sign-info">
            <div class="bold-strenth-row">设备维保二维码</div>
            <div class="strenth-row">浏览器或微信扫码</div>
          </div>
          <div class="device-code">
            <div class="device-code-bold-strenth-row">设备序列号</div>
            <div class="device-code-strenth-row">
              {{ currentBindingDevice ? currentBindingDevice.serialNo : "" }}
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div style="text-align: center">
          <Button @click="downloadImg" type="primary">点击下载</Button>
        </div>
      </template>
    </Modal>

    <!-- 绑定设备弹窗 -->
    <Modal
      @on-visible-change="bindingModalChange"
      v-model="bindingModal.show"
      title="设备绑定"
    >
      <Form
        ref="bindingForm"
        :label-width="100"
        :model="bindingModal.model"
        :rules="bindingModal.rules"
      >
        <FormItem label="授权设备：">
          <span>{{
            currentBindingDevice ? currentBindingDevice.name : ""
          }}</span>
        </FormItem>
        <FormItem prop="farmAndScene" label="基地场景：">
          <Cascader
            @on-change="getFarmAndSceneId"
            :data="farms"
            :load-data="getScenes"
            v-model="bindingModal.model.farmAndScene"
          ></Cascader>
        </FormItem>
        <FormItem prop="sensorGuids" label="绑定列表：">
          <p>
            <Button
              @click="choseBindingOpen"
              :disabled="bindingModal.model.farmAndScene.length === 0"
              size="small"
              >选择设备</Button
            >
          </p>
          <Table
            :data="bindingModal.model.sensorGuids"
            :columns="bindingModal.columns"
            size="small"
          ></Table>
        </FormItem>
      </Form>
      <template #footer>
        <div style="text-align: center">
          <Button
            @click="bindingSubmit"
            type="primary"
            :loading="bindingModal.loading"
            >提交</Button
          >
          <Button @click="() => (bindingModal.show = false)">取消</Button>
        </div>
      </template>
    </Modal>

    <chose-binding
      @save="getBindingDevices"
      v-model="choseBindingShow"
      :default-selection="bindingModal.model.sensorGuids"
      :type-guid="currentBindingDevice ? currentBindingDevice.deviceGuid : ''"
      :farmId="bindingModal.model.farmId"
      :sceneId="bindingModal.model.sceneId"
    ></chose-binding>
  </div>
</template>

<script>
import addAndEdit from "./addAndEdit";
import qrcode from "qrcodejs2";
import html2canvas from "html2canvas";
import choseBinding from "./choseBinding";
export default {
  name: "",
  components: {
    addAndEdit,
    choseBinding,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "自定义名称",
            align: "center",
            minWidth: 100,
            key: "name",
          },
          {
            title: "设备名称",
            align: "center",
            minWidth: 100,
            key: "deviceName",
          },
          {
            title: "基地名称",
            align: "center",
            minWidth: 100,
            key: "farmName",
          },
          // {
          //   title: "设备序列号",
          //   align: "center",
          //   minWidth: 120,
          //   key: "serialNo"
          // },
          {
            title: "质保期(月)",
            align: "center",
            minWidth: 100,
            key: "warrantyPeriod",
          },
          {
            title: "授权时间",
            minWidth: 120,
            align: "center",
            key: "authorizedTime",
          },
          {
            title: "质保到期时间",
            align: "center",
            minWidth: 140,
            key: "qualityDeadline",
          },
          {
            title: "建设单位",
            align: "center",
            minWidth: 100,
            key: "vestingUnit",
          },
          {
            title: "地址",
            align: "center",
            minWidth: 100,
            key: "address",
          },
          {
            title: "绑定设备",
            align: "center",
            minWidth: 100,
            render: (h, { row }) => {
              if (
                row.deviceGuid == "340f9b83-0ab6-4ae1-a1f1-49ce4b70029e" ||
                row.deviceGuid == "3482b08b-ed41-488f-8ecf-ac822ed613c3"
              ) {
                return <span style="color:#fff">暂无可绑定设备</span>;
              }
              return (
                <div>
                  {this.CA("devicell_bind") && (
                    <a onClick={() => this.bindingDevice(row)}>
                      {row.sensorGuids ? "已绑定" : "点击绑定"}
                    </a>
                  )}
                </div>
              );
            },
          },
          {
            title: "操作",
            width: 150,
            align: "center",
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("devicell_edit") && (
                    <a
                      class="iconfont"
                      title="修改信息"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("devicell_nameplate") && (
                    <a
                      title="生成铭牌"
                      style="padding:0 10px;"
                      class="iconfont"
                      onClick={() => this.showNameplate(row)}
                    >
                      &#xe685;
                    </a>
                  )}
                  {this.CA("devicell_del") && (
                    <Poptip
                      confirm={true}
                      transfer={true}
                      title="确定删除该授权设备吗?"
                      onOn-ok={() => this.delete(row)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      modal: {
        show: false,
        title: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      editData: null,
      search_data: {},

      // 当前进行操作的授权设备
      currentBindingDevice: null,
      // 铭牌展示
      nameplateShow: false,

      // 选择绑定设备弹窗
      choseBindingShow: false,
      farms: [],

      // 绑定设备弹窗
      bindingModal: {
        show: false,
        loading: false,
        model: {
          farmId: "",
          sceneId: "",
          farmAndScene: [], //基地和场景id
          sensorGuids: [],
        },
        rules: {
          farmAndScene: [
            {
              required: true,
              type: "array",
              message: "请选择设备所在基地场景",
            },
          ],
          sensorGuids: [
            {
              required: true,
              type: "array",
              trigger: "change",
              message: "绑定设备列表不能为空",
            },
          ],
        },
        columns: [
          {
            title: "设备名称",
            key: "groupName",
            render: (c, { row }) => {
              return <span>{row.groupName || row.name}</span>;
            },
          },
          {
            title: "操作",
            width: 60,
            render: (h, { index }) => {
              return (
                <span>
                  <Icon
                    onClick={() => this.cancelSelect(index)}
                    style="cursor:pointer"
                    type="md-trash"
                    color="red"
                    title="取消该设备"
                  ></Icon>
                </span>
              );
            },
          },
        ],
      },
      authorizedEquipment: [],
      farmList: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "添加设备",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "自定义名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "授权设备",
              component: "select",
              field: "deviceGuid",
              data: this.authorizedEquipment,
              defaultValue: "",
              parameterField: "guid",
              showField: "deviceName",
            },
            {
              conditionName: "基地选择",
              component: "select",
              field: "farmId",
              data: this.farms,
              defaultValue: "",
              parameterField: "value",
              showField: "label",
            },
            {
              conditionName: "建设单位",
              component: "input",
              field: "vestingUnit",
              defaultValue: "",
            },
            {
              conditionName: "地址",
              component: "input",
              field: "address",
              defaultValue: "",
            },
            // {
            //   conditionName: "设备名称",
            //   component: "input",
            //   field: "deviceName",
            //   defaultValue: "",
            // },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    add() {
      this.editData = null;
      this.modal = {
        show: true,
        title: "新增设备授权",
      };
    },
    submitSuccess() {
      this.modal = {
        show: false,
        title: "",
      };
      this.getList();
    },
    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    // 设备列表
    getList() {
      if (!this.CA("devicell_check")) return;
      this.table.loading = true;
      this.$post(this.$api.DEVICE_LL.AUTHORIZATION_LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        vestingNo: localStorage.companyNo,
        ...this.search_data,
      })
        .then((res) => {
          this.page.total = +res.total;
          this.table.data = res.list;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getDevice() {
      this.$post(this.$api.DEVICE_LL.LIST, {
        pageNo: 1,
        pageSize: 99,
      }).then((res) => {
        this.authorizedEquipment = res.list;
      });
    },
    edit(row) {
      this.editData = row;
      this.modal = {
        show: true,
        title: "修改设备授权",
      };
    },
    delete(row) {
      this.$post(this.$api.DEVICE_LL.AUTHORIZATION_DELETE, {
        guid: row.guid,
      })
        .then(() => {
          this.$Message.success("删除成功");
          this.getList();
        })
        .catch(() => {
          this.$Message.error("操作失败");
        });
    },
    bindingDevice(bindingDevice) {
      this.currentBindingDevice = bindingDevice;
      if (bindingDevice.sensorGuids) {
        this.bindingModal.model.farmId = bindingDevice.farmId;
        this.bindingModal.model.sceneId = bindingDevice.sceneId;
        this.bindingModal.model.farmAndScene = [
          bindingDevice.farmId,
          bindingDevice.sceneId,
        ];
        let sensorGuids = bindingDevice.sensorGuids.split(",");
        let sensorNames = bindingDevice.sensorNames
          ? bindingDevice.sensorNames.split(",")
          : [];
        let modelData = [];
        for (let i = 0; i < sensorGuids.length; i++) {
          let item = {
            guid: sensorGuids[i],
          };
          try {
            item.name = sensorNames[i];
          } catch (e) {
            item.name = "";
          }
          modelData[i] = item;
        }
        this.bindingModal.model.sensorGuids = modelData;
      }
      this.bindingModal.show = true;
    },
    // 获取基地数据
    getFarms() {
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: 1,
        pageSize: 99999,
      }).then((res) => {
        this.farms = res.list.map((item) => ({
          children: [],
          loading: false,
          label: item.name,
          value: item.id,
        }));
      });
    },
    // 获取场景数据
    getScenes(farm, callback) {
      farm.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.LIST, {
        farmId: farm.value,
        pageNo: 1,
        pageSize: 99999,
      }).then((res) => {
        if (res.list.length > 0) {
          farm.children = res.list.map((item) => ({
            value: item.id,
            label: item.name,
          }));
        } else {
          this.$nextTick(() => {
            delete farm.children;
            delete farm.loading;
          });
        }
        farm.loading = false;
        callback();
      });
    },
    // 保存选中的基地和场景
    getFarmAndSceneId(data) {
      if (data && data.length > 1) {
        this.bindingModal.model.farmId = data[0];
        this.bindingModal.model.sceneId = data[1];
      } else {
        this.bindingModal.model.farmId = "";
        this.bindingModal.model.sceneId = "";
      }
    },
    // 打开选择绑定设备弹窗
    choseBindingOpen() {
      this.choseBindingShow = true;
    },
    // 选定绑定的设备
    getBindingDevices(list) {
      this.bindingModal.model.sensorGuids = list;
      this.choseBindingShow = false;
      this.$refs.bindingForm.validateField("sensorGuids");
    },
    // 取消绑定某一行
    cancelSelect(index) {
      this.bindingModal.model.sensorGuids.splice(index, 1);
      this.$refs.bindingForm.validateField("sensorGuids");
    },
    // 提交绑定
    bindingSubmit() {
      this.$refs.bindingForm.validate((rs) => {
        if (!rs) return;
        let params = {};
        params.guid = this.currentBindingDevice.guid;
        params.farmId = this.bindingModal.model.farmId;
        params.sceneId = this.bindingModal.model.sceneId;
        if (
          this.currentBindingDevice.deviceGuid ==
          "c9207b50-e61e-45be-a378-4fb61d0bcefd"
        ) {
          params.sensorGuids = this.bindingModal.model.sensorGuids
            .map((item) => item.id)
            .join(",");
        } else if (
          this.currentBindingDevice.deviceGuid ==
          "de282d14-9fd0-407b-9c67-0e67ae3c0af0"
        ) {
          params.sensorGuids = this.bindingModal.model.sensorGuids
            .map((el) => {
              let guids = el.childList.map((el) => el.guid).join(",");
              return guids;
            })
            .join(",");
        } else {
          params.sensorGuids = this.bindingModal.model.sensorGuids
            .map((item) => item.guid)
            .join(",");
        }
        params.sensorNames = this.bindingModal.model.sensorGuids
          .map((item) => item.name || item.groupName)
          .join(",");
        this.bindingModal.loading = true;
        this.$post(this.$api.DEVICE_LL.AUTHORIZATION_BINDING, params)
          .then((res) => {
            this.$Message.success("绑定成功");
            this.bindingModal.show = false;
            this.getList();
          })
          .finally(() => {
            this.bindingModal.loading = false;
          });
      });
    },
    // 弹窗关闭回调
    bindingModalChange(visible) {
      if (visible) return;
      this.currentBindingDevice = null;
      this.$refs.bindingForm.resetFields();
      this.bindingModal.loading = false;
      this.bindingModal.model.farmId = "";
      this.bindingModal.model.sceneId = "";
    },
    showNameplate(data) {
      this.currentBindingDevice = data;
      if (!this.qrcodeFactory) {
        this.qrcodeFactory = new qrcode(this.$refs.qrcode, {
          text: "http://125.87.2.235:8012/?guid=" + data.guid,
          width: 238,
          height: 238,
          colorDark: "#000000",
          colorLight: "#ffffff",
          // correctLevel: qrcode.CorrectLevel.H
        });
      } else {
        this.qrcodeFactory.makeCode(
          "http://125.87.2.235:8012/?guid=" + data.guid
        );
      }
      this.nameplateShow = true;
    },
    // 下载铭牌
    downloadImg() {
      html2canvas(this.$refs.nameplate).then((canvas) => {
        let dataURL = canvas.toDataURL();
        let a = document.createElement("a");
        a.download = this.currentBindingDevice.name;
        a.href = dataURL;
        a.click();
      });
    },
  },
  mounted() {
    this.getList();
    this.getDevice();
    this.getFarms();
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>